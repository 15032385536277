<template>



  <div v-if="carregandoPagina" class="col-md-12" style="text-align: center;padding-top: 255px;">
    <pm-ProgressSpinner />
  </div>
 <section v-if="!carregandoPagina">
      <pm-Breadcrumb :home="home" :model="items_bread" />
      <div class="row">
        <div class="col-md-7">
          <h2 class="titulo-links-escolha" style="vertical-align:middle !important">
            Registrar Alunos <span class="cabecario-turma2" v-if="this.ativa == 1"> Turma Habilitada </span>
          </h2>
        </div>
        <div class="col-md-5" >
          <pm-Button  type="button" @click="this.$router.push({name: 'turmas', params: {pg: this.pg} });"
            icon="pi pi-angle-left"
            label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
          </pm-Button>

          <pm-Button  type="button" @click="irOutraPg(0)"
            icon="pi pi-id-card"
            label="DISCIPLINAS" title="DISCIPLINAS"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
          </pm-Button>

          <pm-Button  type="button" @click="irOutraPg(1)"
            icon="pi pi-id-card"
            label="PROFESSOR" title="PROFESSOR"  class="p-button-sm p-button-info btn-color" style="margin-top: 35px;float: right;">
          </pm-Button>
        </div>
      </div>
      <div class="pm-card">
        <!-- <pm-PickList v-model="alunos_item"
             @move-to-target="moveToTarget"  @move-to-source="moveToSource"
             listStyle="height:342px" dataKey="id" id="alunturma">
              <template #sourceheader>
                  <div class="p-input-filled" style="margin-top:10px">
                    <pm-InputText placeholder="Buscar Alunos " type="text" style="width:350px;" v-model="aluno" @keyup.enter="buscarAlunos"/>
                  </div>
              </template>
              <template #targetheader>
                  Alunos da Turma
              </template>

              <template #movecontrolsstart>
              </template>

              <template #item="slotProps">
                  <div >
                    <div >
                        <div>
                            <span class="">
                              <span class="badge badge-secondary2" style="margin-right:15px;">{{slotProps.item.matricula}}</span>
                               {{slotProps.item.nome_completo}}
                              <span style="margin-left:10px;" class="badge bg-danger table_style" :title="slotProps.item.qual_deficiencia" v-if="slotProps.item.deficiencia == 1">NEE</span>
                            </span>
                        </div>
                    </div>
                  </div>
              </template>
        </pm-PickList> -->
        <div class="row">
          <div class="col-md-6">
            <pm-DataTable :value="alunos" v-model:selection="alunoSelecionado" dataKey="id" class="p-datatable-customers"
            stripedRows scrollable :rowHover="true" selectionMode="multiple" @dblclick="addAluno" :metaKeySelection="false" :resizableColumns="true" scrollHeight="400px" :virtualScrollerOptions="{ itemSize: 46 }">
              <template #header>
                <div class="flex justify-content-center align-items-center" style="padding: 0px">
                  <h5 class="m-0">Buscar Alunos</h5>
                  <span class="p-input-icon-left col-3" style="padding: 0px">
                    <pm-InputText placeholder="Nome " type="text" style="width:170px;" v-model="aluno" @keyup.enter="buscarAlunos"/>
                  </span>
                  <span class="p-input-icon-left col-6" style="padding: 0px">
                    <pm-InputText placeholder="Sobrenome" type="text" style="width:200px;" v-model="alunosobrenome" @keyup.enter="buscarAlunosSobrenome"/>
                  </span>
                </div>
              </template>

              <!-- <pm-Column selectionMode="multiple" style="flex: 0 0 3.5rem; "></pm-Column> -->

              <pm-Column field="matricula" header="Matrícula" style="flex: 0 0 7.5rem">
                <template #body="alunos">
                  <span class="badge bg-secondary">{{alunos.data.matricula}}</span>
                </template>
              </pm-Column>

              <pm-Column field="nome_completo"  header="Nome" style="min-width: 200px"></pm-Column>

            </pm-DataTable>
            <pm-Button v-if="alunoSelecionado" style="float:right;" icon="pi pi-plus" class="mt-2" label="Adicionar à Turma" @click="addAluno"></pm-Button>
          </div>

          <div class="col-md-6">
            <pm-DataTable :value="alunosTurma" v-model:selection="alunoSelecionado" dataKey="id" class="p-datatable-customers"
            scrollable scrollHeight="400px" selectionMode="single" @dblclick="deletarAluno" :metaKeySelection="false" :virtualScrollerOptions="{ itemSize: 46 }">
              <template #header>
                <div class="flex justify-content-center align-items-center" style="padding: 7px">
                  <h5 class="m-0">Alunos na Turma</h5>
                  <!-- <span class="p-input-icon-left col-6" hidden style="padding: 0px">
                    <i class="pi pi-search" />
                    <pm-InputText placeholder="Buscar Alunos " type="text" style="width:320px;" v-model="aluno2" @keyup.enter="buscarAlunos"/>
                  </span> -->
                </div>
              </template>

              <!-- <pm-Column selectionMode="single" style="flex: 0 0 3.5rem"></pm-Column> -->

              <pm-Column field="matricula" header="Matricula" style="flex: 0 0 7.5rem">
                <template #body="alunosTurma">
                  <span class="badge bg-secondary">{{alunosTurma.data.matricula}}</span>
                </template>
              </pm-Column>

              <pm-Column field="nome_completo" header="Nome" style="min-width: '200px'"></pm-Column>


            </pm-DataTable>
            <pm-Button v-if="alunoSelecionado" style="float:right;" icon="pi pi-trash" label="Tirar da Turma" class="p-button-danger mt-2" @click="deletarAluno"></pm-Button>
          </div>

        </div>
      </div>

      <va-modal v-model="abrirModalRemover"  hide-default-actions >
        <template #header>
          <h2 style="color: #2196f3;">Remover Aluno na Turma</h2>
        </template>
        <slot>
          <p><b>Deseja remover o aluno(a) <span class="badge badge-secondary2" style="font-size: 15px!important;">{{nome_aluno}}</span> ? Selecione o motivo!</b></p>
            <div class="row" style="margin-bottom:20px;">
              <div class="col-md-12">
                <fieldset>
                    <va-radio
                      option="option0"
                      v-model="radioSelectedOption"
                      label="Transferência interna do aluno"
                    />
                    <br>
                    <va-radio
                      option="option1"
                      v-model="radioSelectedOption"
                      label="Saiu da escola (Transferido)"
                    />
                    <br>
                    <va-radio
                      option="option2"
                      v-model="radioSelectedOption"
                      label="Saiu da escola (Evadido)"
                    />
                    <br>
                    <va-radio style="margin-top:10px;"
                      option="option3"
                      v-model="radioSelectedOption"
                      label="Faleceu"
                    />
                    <br>
                    <va-radio style="margin-top:10px;"
                      option="option4"
                      v-model="radioSelectedOption"
                      label="Remover(Apagará todos os dados de presença e notas do aluno nesta turma)"
                    />
                </fieldset>
              </div>
              <div class="col-md-6" v-if="radioSelectedOption=='option0'">
                <div>
                  <div class="form-group col-md-12" >
                    <label style="margin-bottom:15px;">Selecione a turma para qual o aluno será transferido:</label>
                    <select class="form-control" v-model="alunoTurmaTransferida">
                      <option v-for="n in turmasRelacionadas" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-8" style="margin-top:15px" v-if="alunoTurmaTransferida != 0">

                    <pm-Button  type="button" @click="realizarTranferenciaAluno()"
                      label="Realizar transferência" title="Realizar transferência"  class=" p-button btn-color">
                    </pm-Button>
                  </div>
                </div>
              </div>
            </div>

            <pm-Button v-if="radioSelectedOption != 'option0'"  type="button"  @click="removerAlunoMotivo"
              label="Realizar Remoção" title="Realizar ação"  class=" p-button btn-color">
            </pm-Button>
        </slot>
      </va-modal>
  </section>
</template>

<script>

import { Aluno } from "@/class/alunos";
import axios from 'axios'
import { defineComponent } from "vue";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { Turma } from "@/class/turma.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";

export default defineComponent({
  props: {
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
    escola_id:{},
    turno:{},
    ativa:{},
    //Se for 0, nao faz nada. Se for 1 adicionar apenas disciplinas e se for 2 adicionar apenas alunos
    tipoDeAcao: {
      default: 0,
    },
    erroturmaeditar:{},
    pg:{}
  },
  data() {
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Turmas'},
      ],
      radioSelectedOption: "opcao1",
      abrirModalRemover:false,
      tipo_segmento: null,

      ano:null,
      aluno:null,
      alunosobrenome:null,
      aluno2:null,
      alunos:[],
      alunosTurma:[],
      alunos_item: [],

      nome_aluno:null,
      aluno_id:null,
      alunoTurmaTransferida:0,
      turmasRelacionadas:[],
      alunoSelecionado: null,
      carregandoPagina:false
    };
  },

  methods: {
    irOutraPg(pg){
      if(pg == 0){
       this.$router.push({name: 'turma-add-disciplina', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 1){
       this.$router.push({name: 'turma-add-Professor', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 2){
       this.$router.push({name: 'turma-add-aluno', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
    },

    async realizarTranferenciaAluno() {
      try {
        this.carregandoPagina = true;
        let novo = {
          turma_id: this.turma_id,
          turma_id_nova: this.alunoTurmaTransferida,
          aluno_id: this.alunoSelecionado.id,
        }
        let data = await Aluno.TransferenciaInternaAluno(novo);
        this.alunoTurmaTransferida = 0;

        this.abrirModalRemover = !this.abrirModalRemover;

        this.alunos_item = [];
        this.alunoSelecionado = false;
        await this.buscarAlunoNaTurma(0);
        this.$vaToast.init({
            message: "Aluno transferido com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
        this.carregandoPagina = false;
      }
      catch(e) {
        this.carregandoPagina = false;
        this.$vaToast.init({
            message: "Problema ao transferir aluno!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }
    },

    async buscarAlunos(event) {
      if(this.aluno == null){
        this.buscarAlunoNaTurma(0);
      }

      let data =  await axios.get('/alunos/v2/'+this.aluno);
      this.alunos = [];
      this.alunos = data.data;

      this.alunos_item = [];
      this.buscarAlunoNaTurma(1);
    },
    async buscarAlunosSobrenome(event) {
      if(this.aluno == null){
        this.buscarAlunoNaTurma(0);
      }

      let data =  await axios.get('/alunos/v2/'+this.aluno+'/sobrenome/'+this.alunosobrenome+'/1');
      this.alunos = [];
      this.alunos = data.data;

      this.alunos_item = [];
      this.buscarAlunoNaTurma(1);
    },

    async buscarAlunoNaTurma(n){
      try {
        if(n==0){
          await this.alunosQNaoSaoDaTurma();
        }

        let data = await Turma.obtemAlunosTurma(this.turma_id);
        this.alunosTurma = data.data;

        this.alunos_item.push(this.alunos);
        this.alunos_item.push(this.alunosTurma);
      } catch (e) {
        this.carregandoPagina = false;
      }
    },

    async alunosQNaoSaoDaTurma(){
      try {
        let data = await Aluno.obtemTodos2();
        // this.alunos = [];
        this.alunos = data.data;

      } catch (e) {
        this.carregandoPagina = false;
      }
    },

    async identificarTipoSegmento(){
      let data = await SegmentoEscolar.obtemUm(this.etapa_id);
      this.tipo_segmento = data.data.tipo;
    },

    async turmaConfig(){
       let turma = {
        turma_id: this.turma_id,
        etapa_id: this.etapa_id,
        periodo_id: this.periodo_id,
        escola_id: this.escola_id,
      }

      let resp_turma = await Turma.aturma(turma);
      let  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
      ];

      this.items_bread = tt;
    },

    async addAluno(/*payload*/){
      if (this.alunoSelecionado == null) {
        this.$vaToast.init({
          message: "Selecione um aluno",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: 'warning',
          fullWidth: false,
        });
      }else{
        this.carregandoPagina = true;
        let aluno;
        for (let index = 0; index < this.alunoSelecionado.length; index++) {
          aluno = {
            ano: this.ano,
            turma_id: this.turma_id,
            aluno_id: this.alunoSelecionado[index].id,
          }
          // aluno.aluno_id.push(this.alunoSelecionado[index].id);
          // aluno.ano = this.ano;
          // aluno.turma_id = this.turma_id;
        let data = await Aluno.addAlunoTurma(aluno);
        }
        this.alunosQNaoSaoDaTurma()
        this.buscarAlunoNaTurma(1);
        this.alunoSelecionado = false;
        //setTimeout(function() {
        //  this.buscarAlunoNaTurma(0);
        //}, 1000);
        this.carregandoPagina = false;
      }
    },

    async deletarAluno(event){
      if(this.alunoSelecionado == null){
        this.$vaToast.init({
          message: "Selecione um aluno",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: 'warning',
          fullWidth: false,
        });
      }else{
        this.carregandoPagina = true;
        let payload = {
          ano: this.ano,
          aluno_id: this.alunoSelecionado.id,
          turma_id: this.turma_id,
        };
        let dt = await Aluno.deletarAlunoTurmaV2(payload);
        console.log('alo',dt)
        if(dt.data == "Turma Ativa"){
          this.abrirModalRemover = true;
          this.alunos = [];
          await this.buscarAlunoNaTurma(0);
          this.nome_aluno = this.alunoSelecionado.nome_completo;
          await this.buscaTurmasRelacionadas();
        }
        this.carregandoPagina = false;
        await this.buscarAlunoNaTurma(0);
      }
    },

    // moveToTarget(event){
    //   let payload = {
    //     ano: this.ano,
    //     // aluno_id: event.items[0].id,
    //     aluno_id: this.alunos.id,
    //     turma_id: this.turma_id,
    //   };
    //   // for (let index = 0; index < this.alunos.length; index++) {
    //   //   this.alunos[index];
    //   //   payload.aluno_id = this.alunos.id;
    //   // }
    //   // for (const el of this.alunos) {
    //   //   payload.aluno_id = el.id;
    //   // }
    //   this.addAluno(payload);
    // },

    // moveToSource(event){
    //   let payload = {
    //     ano: this.ano,
    //     aluno_id: event.items[0].id,
    //     turma_id: this.turma_id,
    //   };
    //   this.aluno_id = event.items[0].id;
    //   this.deletarAluno(payload,event);
    // },

    async removerAlunoMotivo() {
      try {
        this.carregandoPagina = true;
        let motivo = "";
        if (this.radioSelectedOption == "option1") {
          motivo = "Transferido";
        }
        if (this.radioSelectedOption == "option2") {
          motivo = "Evadido";
        }
        else if (this.radioSelectedOption == "option3") {
          motivo = "Faleceu";
        }
        else if (this.radioSelectedOption == "option4") {
          motivo = "Entrou na turma errada";
        }
        let novo = {
          motivo: motivo,
          turma_id: this.turma_id,
          aluno_id: this.alunoSelecionado.id,
        }
        let data = await Aluno.RemoverAlunoTurma(novo);
        this.alunoSelecionado = false;
        await this.buscarAlunoNaTurma(0);

        this.abrirModalRemover = !this.abrirModalRemover;
        this.$vaToast.init({
            message: "Operação realizada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
        this.carregandoPagina = false;
      }
      catch(e) {
        this.carregandoPagina = true;
        this.$vaToast.init({
            message: "Problema ao transferir aluno!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }
    },

    async buscaTurmasRelacionadas() {
      let novo = {
        ano: this.ano,
        escola_id:this.escola_id,
        segmento_id: this.etapa_id,
      }

      let data = await Turma.showEscolaSegmentoTurma(novo);
      this.turmasRelacionadas = [];
      for (const el of data.data) {
        //nao mostra a turma que ele atualmente está
        if (el.id == this.turma_id)
          continue;
        this.turmasRelacionadas.push(el);
      }
    },
  },
  async beforeMount(){
    try {
      if(this.turma_id != null){
        this.ano = new Date().getFullYear();
        await this.turmaConfig();
        await this.identificarTipoSegmento();
        await this.buscarAlunoNaTurma(0);
      }else{
        this.$router.push({ name: "turmas"});
      }
    } catch (e) {
      this.carregandoPagina = false;
      console.log(e);
    }
  }
});
</script>

<style>
  #alunturma .p-picklist-source-controls{
   display:none;
 }
 #alunturma .p-picklist-target-controls{
   display:none;
 }

 #alunturma .p-picklist-transfer-buttons .p-button:nth-child(2) {
    display:none;
 }

 #alunturma .p-picklist-transfer-buttons .p-button:nth-child(4) {
    display:none;
 }

 .badge-secondary2 {
    color: #fff;
    background-color: #6c757d;
    font-size: 9px;
 }

 .cabecario-turma2 {
    background-color: rgb(59, 130, 246);
    color: #fff;
    margin-left: 15px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 13px;
    vertical-align:middle !important
}
</style>

<style lang="scss" scoped>
.virtualscroller-demo {
	::v-deep(.p-virtualscroller) {
		height: 200px;
		width: 200px;
		border: 1px solid var(--surface-border);

        .scroll-item {
            background-color: var(--surface-card);
            display: flex;
            align-items: center;
        }

        .custom-scroll-item {
            flex-direction: column;
            align-items: stretch;
        }

        .odd {
            background-color: var(--surface-ground);
        }
	}

    ::v-deep(.p-horizontal-scroll) {
        .p-virtualscroller-content {
            display: flex;
            flex-direction: row;
        }

        .scroll-item {
            writing-mode: vertical-lr;
        }
    }

    ::v-deep(.custom-loading > .p-virtualscroller-loader) {
        display: block;
    }
}
</style>
